.careersPageHeader h1 {
    font-family: 'moonDream';
    font-size: 5rem;
}

.careersPageHeaderSub {
    background: #00ff96;
}

.careersPageHeaderSub h2 {
    color: #2696ff;
    font-weight: 200;
    font-size: 4rem;
    line-height: 1.1;
    position: relative;
    z-index: 1;
    display: inline-block;
}

.careersPageHeaderSub h2 span {
    display: block;
    text-align: right;
    font-size: 0.5em;
}

.careersPageHeaderSub h2 .sub-text {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 2;
    width: 11rem;
    transform: translate(-190%, -35%);
}

.careersPageHeaderSub h2 .decor-1 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 4rem;
    transform: translate(-70%, -115%);
}

.careersPageHeaderSub h2 .decor-2 {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 2;
    width: 1.5rem;
    transform: translate(250%, -50%);
}

.careersPageHeaderSub h2 .decor-3 {
    position: absolute;
    right: 50%;
    bottom: 0;
    z-index: 2;
    width: 5rem;
    transform: translate(370%, -120%);
}

.careersPageHeaderSub h2 .decor-4 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 2.2rem;
    transform: translate(100%, -250%);
}

.jobOpeningsHead h2 {
    display: inline-block;
    line-height: 1;
    font-size: 2.2rem;
    padding-left: 4.8rem;
    position: relative;
    z-index: 1;
}

.jobOpeningsHead h2 .careerArrow {
    border-bottom: 0.16rem solid #000;
    border-right: 0.16rem solid #000;
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    top: 6%;
    bottom: 6%;
    aspect-ratio: 1;
    overflow: hidden;
}

.jobOpeningsHead h2 .careerArrow::after {
    border-top: 0.16rem solid #000;
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 150%;
    transform-origin: left top;
    transform: translateY(-50%) rotate(45deg);
}

.jol-item {
    background: #2696ff;
    padding: 0 0 6rem;
    height: 100%;
    position: relative;
    z-index: 1;
}

.jobOpeningsList .row .jol-outer:nth-child(2n) .jol-item {
    background: #00ff96;
}

.jol-item h3 {
    padding: 0 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1.2;
    min-height: 4rem;
}

.jol-item ul {
    margin: 0;
    padding:  0 1.5rem;
    list-style-type: none;
}

.jol-item ul li {
    font-size: 0.8rem;
    line-height: 1.4;
    padding-bottom: 1rem;
    padding-left: 1.8rem;
    position: relative;
    z-index: 1;
}

.jol-item ul li::after {
    border-bottom: 0.09rem solid #000;
    border-right: 0.09rem solid #000;
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    top: 6%;
    overflow: hidden;
    width: 1.1rem;
    height: 1.1rem;
    background: linear-gradient( 45deg, #0000 46%, #000f 8%, #0000 54% );
}

.jol-btn {
    position: absolute;
    z-index: 2;
    bottom: 3rem;
    left: 50%;
    transform: translate(-50%, 50%);
    background: #fff200;
    border: 0.09rem solid #000;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    height: 2rem;
    padding-right: 3rem;
    padding-left: 1rem;
    box-shadow: none !important;
    outline: none !important;
}

.jol-btn::after {
    border-bottom: 0.1rem solid #000;
    border-right: 0.1rem solid #000;
    content: "";
    position: absolute;
    z-index: 1;
    right: 0.575rem;
    top: 0.575rem;
    overflow: hidden;
    width: 0.85rem;
    height: 0.85rem;
    background: linear-gradient( 45deg, #0000 45%, #000f 10%, #0000 55% );
}

.jol-btn::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 2rem;
    z-index: 2;
    content: "";
    border-left: 0.09rem solid #000;
}

/*------------------------------------------------------ APPLY NOW ----------------*/

.apllyNowFormWrap {
    padding: 6rem 0;
}

.form-field {
    border: 1px solid #0008 !important;
    outline: none !important;
    box-shadow: none !important;
    background: #fff;
    width: 100%;
    height: 15rem;
    resize: none;
    padding: 0.9rem 1rem;
    font-size: 0.95rem;
    color: var(--swcBlack);
    line-height: 1.4;
}

#careerFormField01 {
    background: #2696ff;
}

#careerFormField02 {
    background: #fff200;
}

#careerFormField03 {
    background: #00ff96;
}

#careerFormField04 {
    background: #ed1f24;
}

.form-text {
    position: relative;
    z-index: 1;
    color: var(--swcBlack);
    padding-left: 15%;
    margin: 0;
    padding-bottom: 1.2em;
    font-size: 1.05rem;
    line-height: 1.4;
}

.form-text-decor {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(5%);
    z-index: 1;
    width: 12%;
}

.form-input {
    outline: none !important;
    box-shadow: none !important;
    border: 2px solid #000 !important;
    height: 2.7rem;
    width: 2.7rem;
    background: transparent;
    border-radius: 5px;
    border: 2px solid #000 !important;
    opacity: 0;
    cursor: pointer !important;
}

.formSubmit {
    outline: none !important;
    box-shadow: none !important;
    border: 2px solid #000 !important;
    border-radius: 5px;
    height: 2.7rem;
    width: 9rem;
    font-size: 0.9rem;
    line-height: 1;
    text-transform: capitalize;
    font-weight: 700;
    color: #000 !important;
    background: transparent;
}

.formSubmitWrap,
.form-input-wrap {
    position: relative;
    z-index: 1;
}

.formSubmitWrap *,
.form-input-wrap * {
    position: relative;
    z-index: 3;
}

.form-input-wrap::after {
    position: absolute;
    content: "";
    z-index: 1;
    inset: 0;
    background-image: url(../assets/images/careers/careers-atr-09.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50% auto;
    border-radius: 5px;
    border: 2px solid #000 !important;
}

.form-input-wrap::before,
.formSubmitWrap::before {
    position: absolute;
    content: "";
    z-index: 2;
    inset: 2px;
    background: transparent;
    border-radius: 8px;
    border: 6px solid #d8d8d8;
    border-top-color: transparent;
    border-left-color: transparent;
}

.form-input-wrap.clicked::before,
.formSubmitWrap.clicked::before {
    border-width: 4px;
}

@media( min-width: 1000px ) {
    .form-submit-wrap {
        padding-right: 40%;
    }
}

@media( max-width: 767px ) {
    .careersPageHeaderSub h2 {
        font-size: 3rem;
    }
    .careersPageHeaderSub h2 .sub-text {
        width: 8.5rem;
        transform: translate(-190%, -35%);
    }
    .careersPageHeaderSub h2 .decor-1 {
        width: 3rem;
    }
    .careersPageHeaderSub h2 .decor-2 {
        width: 1rem;
    }
    .careersPageHeaderSub h2 .decor-3 {
        width: 4rem;
    }
    .careersPageHeaderSub h2 .decor-4 {
        width: 1.8rem;
    }
    .jol-item ul li {
        font-size: 0.95rem;
    }
    .jol-item h3 {
        font-size: 1.4rem;
        min-height: 4.6rem;
    }
}

@media( max-width: 575px ) {
    .careersPageHeader h1 {
        font-size: 9vw;
    }
    .careersPageHeaderSub h2 {
        font-size: 2.2rem;
    }
    .careersPageHeaderSub h2 .sub-text {
        width: 6rem;
    }
    .careersPageHeaderSub h2 .decor-1 {
        width: 2.5rem;
    }
    .careersPageHeaderSub h2 .decor-2 {
        width: 1.2rem;
        transform: translate(150%, -70%);
    }
    .careersPageHeaderSub h2 .decor-3 {
        width: 3.5rem;
        transform: translate(320%, -100%);
    }
    .careersPageHeaderSub h2 .decor-4 {
        width: 1.3rem;
    }
    .jol-btn {
        border: 0.09rem solid #000;
        font-size: 1rem;
        height: 2.4rem;
        padding-right: 3.6rem;
        padding-left: 1.2rem;
    }
    .jol-btn::after {
        right: 0.725rem;
        top: 0.725rem;
        overflow: hidden;
        width: 0.95rem;
        height: 0.95rem;
        background: linear-gradient( 45deg, #0000 45%, #000f 10%, #0000 55% );
    }
    .jol-btn::before {
        width: 2.4rem;
    }
    .jol-item h3 {
        min-height: auto;
        padding-bottom: 1em;
        font-size: 1.6rem;;
    }
    .form-image {
        max-height: 75vmin;
    }
    .form-text {
        font-size: 1rem;
    }
    
}