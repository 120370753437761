.services-page .iph-hamburger span {
    background: var(--swcBlack) !important;
}

.services-page .swc_hamburger span:last-child {
    background: var(--primaryColor) !important;
}

.servicespageBanner {
    padding: max( 12vh, 6rem ) 0 max( 10vh, 4rem ) !important;
}

.servicesListItem {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 100px 15px;
    height: 100%;
}

.servicesListItem_1 {
    background: #2696ff;
}

.servicesListItem_2 {
    background: #00ff96;
}

.servicesListItem_3 {
    background: #fff200;
}

.servicesListItem_4 {
    background: #ed1f24;
}

.servicesListItem_1 h2 {
    font-size: 2.6rem;
    line-height: 0.8;
}

.servicesListItem_1 h2 .sub1 {
    font-size: 0.7em;
}

.servicesListItem_1 h2 .sub2 {
    font-size: 0.615em;
}

.servicesListItem_2 h2 {
    font-size: 2.4rem;
    line-height: 1;
}

.servicesListItem_2 h2 .sub1 {
    font-size: 0.9em;
}

.servicesListItem_3 h2 {
    font-size: 2.8rem;
    line-height: 0.85;
}

.servicesListItem_3 h2 .sub1 {
    font-size: 0.88em;
}

.servicesListItem_4 h2 {
    font-size: 3.5rem;
    line-height: 0.65;
}

.servicesListItem_4 h2 .sub1 {
    font-size: 0.625em;
}

.btn-all-works {
        color: var(--swcBlack) !important;
        background: #00ff96 !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        font-size: 1.2rem;
        padding: 0.7em 2.5em;
        text-decoration: none !important;
        font-weight: 500;
}