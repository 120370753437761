.thoughtsFormWrap small {
  display: none;
}

.thoughts-page .iph-hamburger span {
  background: var(--swcBlack) !important;
}

.thoughts-page .iph-hamburger::after {
  display: none;
}

.thoughts-page .swc_hamburger span:last-child {
  background: var(--primaryColor) !important;
}

.thoughtspageBanner {
    background: #fff200;
    padding: max( 12vh, 6rem ) 0 max( 10vh, 4rem ) !important;
}

.thoughtspageBanner h1 {
    font-size: 5rem;
    font-weight: 800;
    text-transform: uppercase;
}

.thankyouWrap p {
    font-size: 1.1rem;
}

.thankyouWrap h5 {
    font-size: 1.95rem;
    transform: translateY(-5px);
}

.btn-submit {
    color: var(--swcBlack) !important;
    background: #00ff96 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    font-size: 1.2rem;
    padding: 0.3em 1.5em;
}

.thoughtsFormWrap .btn-submit {
    font-size: 1rem;
    min-width: 6rem;
}

.thoughtsFormWrap .form-control {
    background: #00ff96 !important;
    border: none !important;
    resize: none;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.thoughtsFormWrap .form-control::placeholder {
    color: var(--swcBlack);
    opacity: 1;
  }
  
  .thoughtsFormWrap .form-control:-ms-input-placeholder {
    color: var(--swcBlack);
  }
  
  .thoughtsFormWrap .form-control::-ms-input-placeholder {
    color: var(--swcBlack);
  }

  .thoughtsFormWrap p {
    font-size: 1.1rem;
  }

  .homeColumn2.slideToOpen h2 {
    opacity: 0;
    transition-duration: 1s;
  }

  @media( max-width: 767px ) {
    .thoughtspageBanner h1 {
        font-size: 3rem;
    }
    .thoughtspageBanner {
      padding: 7rem 0 3rem !important;
    }
  }