@font-face {
    font-family: 'moonDream';
    src: url('../assets/fonts/moondream-webfont.woff2') format('woff2'),
         url('../assets/fonts/moondream-webfont.woff') format('woff'),
         url('../assets/fonts/moondream-webfont.ttf') format('truetype'),
         url('../assets/fonts/moondream-webfont.svg#moon_dreamregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nirmalaUI';
    src: url('../assets/fonts/nirmala-webfont.woff2') format('woff2'),
         url('../assets/fonts/nirmala-webfont.woff') format('woff'),
         url('../assets/fonts/nirmala-webfont.ttf') format('truetype'),
         url('../assets/fonts/nirmala-webfont.svg#nirmala_uiregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.moonDreamText {
    font-family: 'moonDream' !important;
}