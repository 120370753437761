/*-------- Inner Page Header Navigation ------------*/

.innerPageHeader {
    position: fixed;
    inset: 0 0 auto 0;
    z-index: 50;
    padding: 1rem;
}
/*
.innerPageHeader.blend-in {
    mix-blend-mode: difference;
}
*/
.iph-logoWrap {
    width: 3.5rem;
}

.iph-hamburger {
    position: relative !important;
    inset: auto !important;
    transform: none !important;
    margin-right: 1rem !important;
}

.iph-hamburger.white-ham {
    gap: 0.3rem;
}

.iph-hamburger.white-ham span {
    background: #fff;
    height: 0.2rem;
}

.swc_hamburger.white-ham span:last-child {
    height: 0.25rem;
}

.logoWhite {
    display: none;
}

.submenu {
    overflow: hidden;
}

.submenu.hide {
    height: 0;
    transition: all 0.4s ease-in-out;
}

.hasSubmenu {
    cursor: pointer;
}

@media( max-width: 768px ) {
    .iph-hamburger {
        margin-right: 0 !important;
    }
}