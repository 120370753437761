@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Malayalam:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comforter+Brush&family=Dancing+Script:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
    --primaryColor: #ed1c24;
    --swcBlack: #040707;
    --swcBlue: #2696ff;
    --swcBlueDark: #0e4272;
    --swcGreen: #00ff96;
    --swcGreenDark: #19644b;
    --swcGreenDarkAlt: #00a651;
    --swcWhite: #ffffff;
    --swcYellow: #fff200;
    --swcTextColor: #494949;
}

@font-face {
    font-family: 'dyuthiregular';
    src: url('../assets/fonts/dyuthi-webfont.woff2') format('woff2'), url('../assets/fonts/dyuthi-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'galettemedium';
    src: url('../assets/fonts/galette-med-webfont.woff2') format('woff2'), url('../assets/fonts/galette-med-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body,
html {
    max-width: 100vw;
    overflow-x: hidden;
    font-size: 18px;
    line-height: 1.7;
    color: var(--swcBlack);
    font-family: 'Manrope', sans-serif;
    padding: 0 !important;
}

::-webkit-scrollbar {
    display: none;
  }

* {
    position: relative;
    z-index: 1;
}

.siteHomeWrap * {
    transition: all 1s ease-in-out;
}

.siteHomeWrap {
    background: var(--swcBlack);
    height: 100vh;
    overflow: hidden;
    position: fixed;
    inset: 0;
    z-index: 99;
    display: none;
    transition: none;
}

.home .siteHomeWrap,
.home.from-innerpage .siteHomeWrap {
    display: block;
    transition: height 0s ease-in-out;
}

.home.from-innerpage * {
    transition-delay: 0s !important;
}

.home.inner-page .siteHomeWrap {
    height: 0 !important;
    transition: height 1s 1s ease-in-out;
}

.homeColumn {
    width: 25%;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}

.homeColumn:nth-child(1) {
    left: 0;
}

.homeColumn:nth-child(2) {
    left: 25%;
}

.homeColumn:nth-child(3) {
    left: 50%;
}

.homeColumn:nth-child(4) {
    left: 75%;
}

.homeColumn:hover {
    z-index: 3;
}

.homeColumn::after {
    position: absolute;
    content: "";
    z-index: 9;
    inset: 0 0 0 0;
    background: var(--swcBlack);
    animation: swc_reveal 1.2s 0.3s forwards ease-in-out;
}

@keyframes swc_reveal {
    0% {
        inset: 0 0 0 0;
    }
    100% {
        inset: 0 0 0 100%;
    }
}

@keyframes swc_reveal_mob1 {
    0% {
        inset: 0 0 0 0;
    }
    100% {
        inset: 0 0 100% 0;
    }
}

@keyframes swc_reveal_mob2 {
    0% {
        inset: 0 0 0 0;
    }
    100% {
        inset: 100% 0 0 0;
    }
}

.homeColumnInner {
    overflow: hidden;
    cursor: pointer;
}

.homeColumn1 .homeColumnInner:nth-child(1),
.homeColumn4 .homeColumnInner:nth-child(1) {
    position: absolute;
    z-index: 2;
    inset: 0 0 auto 0;
    height: 50%;
    background: var(--swcWhite);
}

.homeColumn4 .homeColumnInner.servicesWrap {
    background: var(--swcGreen);
}

.homeColumn1 .homeColumnInner:nth-child(2),
.homeColumn4 .homeColumnInner:nth-child(2) {
    position: absolute;
    z-index: 2;
    inset: auto 0 0 0;
    height: 50%;
}

.homeColumn1 .homeColumnInner.logoWrap img {
    width: 48%;
    height: auto;
    max-width: 250px;
}

.homeColumn1 .homeColumnInner.thanathuWrap img {
    width: 60%;
    height: auto;
    max-width: 260px;
}

.homeColumn2 .homeColumnInner.works {
    background-color: var(--swcGreenDark);
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    inset: 0;
    position: absolute;
    z-index: 1;
}

.homeColumn2 .homeColumnInner.works h2 {
    line-height: 0.75;
    font-size: 12vw;
    color: var(--swcWhite);
    flex-wrap: wrap;
    transform: translateY(10%);
    font-family: 'galettemedium';
}

.homeColumn2 .homeColumnInner.works h2 span:first-child {
    font-family: 'dyuthiregular';
    font-size: 1.5em;
    line-height: 0.35;
    transform: translateX(-4%);
}

.homeColumn2 .homeColumnInner.works h2 span:nth-child(4),
.homeColumn2 .homeColumnInner.works h2 span:nth-child(7) {
    transform: rotateY(180deg);
}

.homeColumn3 .homeColumnInner.weirdosWrap {
    background: var(--swcBlue);
    inset: 0;
    position: absolute;
    z-index: 1;
}

.homeColumn3 .homeColumnInner.weirdosWrap h4 {
    font-family: 'galettemedium';
    color: var(--swcBlueDark);
    font-size: 5.5vw;
    display: inline-block;
    transform-origin: left top;
    position: absolute;
    z-index: 3;
    top: 2.5vw;
    left: 2.5vw;
    margin: 0;
    line-height: 0.7;
    transform: rotate(-90deg) translateX(-100%);
}

.homeColumn3 .homeColumnInner.thoughtWrap {
    background: var(--swcYellow);
    position: absolute;
    z-index: 2;
    inset: auto auto 25% 50%;
    transform-origin: center center;
    transform: translate(-50%, -80vh);
    width: 20vw;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: drop_thoughts 1s 1s normal forwards ease-in-out, tilt-n-move-shaking 4s 2s infinite linear;
}

@keyframes tilt-n-move-shaking {
    0% { transform: translate(-50%, 50%) rotate(0deg); }
    25% { transform: translate(-38%, 50%) rotate(14deg); }
    50% { transform: translate(-50%, 50%) rotate(0deg); }
    75% { transform: translate(-62%, 50%) rotate(-14deg); }
    100% { transform: translate(-50%, 50%) rotate(0deg); }
}
  

@keyframes drop_thoughts {
    0% {
        transform: translate(-50%, -80vh);
    }
    45% {
        transform: translate(-50%, 180%);
    }
    82% {
        transform: translate(-50%, 40%);
    }
    100% {
        transform: translate(-50%, 50%);
    }
}

.homeColumn3 .homeColumnInner.thoughtWrap h6 {
    color: var(--swcGreenDark);
    font-size: 3vw;
    line-height: 1;
    transform: scale(1.8);
    filter: blur(10px);
    opacity: 0;
    font-family: 'galettemedium';
    animation: drop_thoughts_text 0.5s 1.5s forwards ease-in-out;
}

@keyframes drop_thoughts_text {
    0% {
        transform: scale(1.8);
        filter: blur(10px);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        filter: blur(0px);
        opacity: 1;
    }
}

.homeColumn4 .homeColumnInner.servicesWrap h5 {
    color: var(--swcGreenDark);
    font-size: 3.5vw;
    line-height: 1;
    font-family: 'galettemedium';
}

.homeColumn4 .homeColumnInner.contactWrap h3 {
    color: var(--swcWhite);
    font-size: 5vw;
    line-height: 0.85;
    font-family: 'galettemedium';
}

.swc_hamburger {
    position: absolute;
    z-index: 999;
    left: 2.5vw;
    top: 2.5vw;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    cursor: pointer;
}

.swc_hamburger span {
    display: block;
    width: 1.75rem;
    height: 0.3rem;
    background: var(--swcBlack);
}

.swc_hamburger span:last-child {
    background: var(--primaryColor) !important;
}

.sidebarNav {
    background: var(--swcBlack) !important;
}

.sidebarNav ul {
    list-style-type: none;
}

.sidebarNav li {
    padding: 0.18rem 0;
}

.sidebarNav  a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    color: var(--swcGreen);
}

.sidebarNav ul li li {
    font-size: 0.9em;
    color: var(--swcBlue);
    text-transform: uppercase;
}

.sidebarNav ul li li a {
    font-size: 1.1em;
}

.sidebarNav ul li {
    text-transform: uppercase;
}

.sidebarNav ul li a,
.sidebarNav ul li * {
    color: var(--swcGreen) !important;
    font-size: 1em !important;
}

.sidebarNav ul li .menuLayer01 a,
.sidebarNav ul li .menuLayer01 *,
.sidebarNav ul li .menuLayer01 {
    color: var(--swcBlue) !important;
    font-size: 0.97em !important;
}

.sidebarNav ul li .menuLayer02 a,
.sidebarNav ul li .menuLayer02 * {
    color: var(--swcGreen) !important;
    font-size: 1em !important;
}


.homeColumn.slideToLeft {
    left: 0;
    width: 0;
    transition: all 1s ease-in-out;
}

.homeColumn.slideToRight {
    left: 100%;
    width: 0;
    transition: all 1s ease-in-out;
}

.homeColumn.slideToRight .homeColumnInner,
.homeColumn.slideToLeft .homeColumnInner {
    opacity: 0;
    transition: all 1.2s ease-in-out;
}

.homeColumn.slideToOpen {
    left: 0;
    width: 100%;
    transition: all 1s ease-in-out;
}

.homeColumn1 .homeColumnInner.boxOpen,
.homeColumn4 .homeColumnInner.boxOpen {
    height: 100% !important;
    z-index: 2 !important;
    transition: all 1s ease-in-out;
}

.homeColumn1 .homeColumnInner.boxClose,
.homeColumn3 .homeColumnInner.weirdosWrap.boxClose,
.homeColumn4 .homeColumnInner.boxClose {
    height: 0% !important;
    z-index: 1 !important;
    transition: all 1s ease-in-out;
}

.homeColumn1 .homeColumnInner.boxClose *,
.homeColumn4 .homeColumnInner.boxClose * {
    opacity: 0;
    transition: all 1.2s ease-in-out;
}

.homeColumn3 .homeColumnInner.thoughtWrap.boxClose {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.homeColumn3 .homeColumnInner.thoughtWrap.boxOpen {
    width: 150vw;
}

.homeColumn3 .homeColumnInner.thoughtWrap.boxOpen h6 {
    color: var(--swcYellow);
    transition: all 0.1s;
}


/*------------ WORKS ------- bg video ----*/

.homeColumnInner.works h2 {
    z-index: 3;
}

.worksvideoBG {
    position: absolute;
    z-index: 1;
    top: 0;
    left: -25vw;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.worksvideoBG::after {
    position: absolute;
    z-index: 3;
    inset: 0;
    content: "";
    background: #000;
    opacity: 0.6;
}

.bannerVideoWrap {
    width: 100vw;
    max-height: 100vh;
    overflow: hidden;
}

.slideToOpen .worksvideoBG {
    left: 0;
}

.worksvideoBG video,
.bannerVideoWrap video {
    width: 100%;
    aspect-ratio: 1/0.5625;
    min-height: 100vh;
    z-index: 1;
}

.servicespageBanner.all-services {
    background: var(--swcGreen);
}

.servicespageBanner.all-services h1 {
    color: var(--swcGreenDark);
}

.servicesParent .servicesListItem_1 {
    background-image: url('../assets/images/brandidentity-cardbg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.servicesParent .servicesListItem_1 h2 {
    line-height: 0.85;
    font-size: 2rem;
}

.servicesParent .servicesListItem_2 {
    background-image: url('../assets/images/advertising-cardbg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.servicesParent .servicesListItem_2 h2 {
    font-size: 2rem;
    line-height: 1;
}

.servicesParent .servicesListItem_3 {
    background-image: url('../assets/images/contentandstories-cardbg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.servicesParent .servicesListItem_3 h2 {
    font-size: 2.1rem;
    line-height: 1.05;
}

.servicesParent .servicesListItem_3 .sub1 {
    font-size: 0.92em;
}

.servicesParent .servicesListItem_4 {
    background-image: url('../assets/images/digitalmarketing-cardbg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.servicesParent .servicesListItem_4 h2 {
    font-size: 2.2rem;
    line-height: 0.85;
}

.servicesParent .servicesListItem_4 h2 .sub1 {
    font-size: 0.64em;
}

.backToTop {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 999;
    width: 2rem;
    height: 2rem;
    background: var(--swcBlack);
    cursor: pointer;
}

.backToTop::after {
    position: absolute;
    content: "";
    z-index: 2;
    left: 50%;
    top: 50%;
    transform-origin: center center;
    transform: translate(-50%, -30%) rotate(-45deg);
    width: 30%;
    height: 30%;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
}

@media( min-width: 1400px ) {
    body,
    html {
        font-size: 20px;
    }
}

@media( max-width: 767px ) {
    body,
    html {
        font-size: 16px;
    }
    .homeColumn {
        width: 50vw;
        height: calc( 50vh - 25px );
    }
    .homeColumn:nth-child(1),
    .homeColumn:nth-child(3) {
        left: 0;
    }
    .homeColumn:nth-child(2),
    .homeColumn:nth-child(4) {
        left: 50vw;
    }
    .homeColumn:nth-child(1),
    .homeColumn:nth-child(2) {
        top: 0;
    }
    .homeColumn:nth-child(4),
    .homeColumn:nth-child(3) {
        top: calc( 50vh - 25px );
    }
    .homeColumn:nth-child(1)::after,
    .homeColumn:nth-child(2)::after {
        animation: swc_reveal_mob1 3s 1s forwards ease-in-out;
    }
    .homeColumn:nth-child(3)::after,
    .homeColumn:nth-child(4)::after {
        animation: swc_reveal_mob2 3s 1s forwards ease-in-out;
    }
    .homeColumn1 .homeColumnInner.logoWrap img {
        width: 40%;
    }
    
    .homeColumn1 .homeColumnInner.thanathuWrap img {
        width: 50%;
    }
    .homeColumn2 .homeColumnInner.works h2 {
        font-size: min( 85px, 22vw );
    }  
    .worksvideoBG {
        left: 50%;
        width: 100vw;
        height: 100%;
        transform: translateX(-50%);
    }  
    .worksvideoBG video, .bannerVideoWrap video {
        min-height: auto;
        height: 100%;
        width: auto;
        aspect-ratio: 1.778/1;
        transform: translateX(-50%);
        left: 50%;
    }
    .homeColumn4 .homeColumnInner.servicesWrap h5 {
        font-size: min( 36px, 9vw );
    }
    .homeColumn4 .homeColumnInner.contactWrap h3 {
        font-size: min( 42px, 12vw );
    }
    .homeColumn3 .homeColumnInner.weirdosWrap h4 {
        font-size: min( 42px, 12vw );
        top: 5vw;
        left: 5vw;
    }
    .homeColumn3 .homeColumnInner.thoughtWrap {
        inset: auto auto 30% 50%;
        width: 40vw;
    }
    .homeColumn3 .homeColumnInner.thoughtWrap h6 {
        font-size: min( 34px, 7vw );
    }
    .homeColumn3 .homeColumnInner.thoughtWrap.boxOpen {
        width: 150vh;
    }
    .homeColumn.slideToOpen {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        transition: all 2s ease-in-out;
    }
    .homeColumn.slideToOpen .worksvideoBG {
        left: 0;
        transform: translateX(0);
    }
    .servicesParent .servicesListItem {
        aspect-ratio: 1/0.75 !important;
    }
    .servicesParent .servicesListItem h2 {
        font-size: 2.2rem;
    }
    .servicesParent .servicesListItem_3 h2,
    .servicesParent .servicesListItem_4 h2 {
        font-size: 2.5rem;
    }
    .backToTop {
        bottom: 1rem;
        right: 1rem;
        width: 2.2rem;
        height: 2.2rem;
    }
}