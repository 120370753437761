.digitalPageContent p {
    text-align: justify;
}

.servicespageBanner {
    padding: max( 12vh, 6rem ) 0 max( 10vh, 4rem ) !important;
}

.digital-seo-page .servicespageBanner,
.digital-lead-page .servicespageBanner {
    background: #a186be;
}

.digital-ppc-page .servicespageBanner {
    background: #55a6ff;
}

.content-stories-page .servicespageBanner {
    background: #5cd1ff;
}

.unbranding-page .servicespageBanner {
    background: #fea6d1;
}

.unadvertising-page .servicespageBanner {
    background: #e0bf77;
}

.unbranding-page .servicespageBanner.secondary {
    padding: max( 10vh, 4rem ) 0  !important;
    background: #01c97b;
}

.category .servicespageBanner h1 {
    font-size: 4.5rem;
    font-weight: 800;
    text-transform: uppercase;
}

.unbranding-page .servicespageBanner h1,
.unadvertising-page .servicespageBanner h1 {
    font-size: 92px;
    font-weight: 800;
    text-transform: uppercase;
    padding-left: 1em;
    display: inline-block;
}

.unadvertising-page .servicespageBanner h1 {
    padding-left: 1.2em;
}

.un-text img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) scale(1.5) translateX(-12%);
    width: 20%;
}

.lg-img01,
.lg-img02,
.lg-img03 {
    width: auto;
    height: 330px;
}

.scriptText {
    font-family: 'Dancing Script', cursive;
    font-size: 3rem;
}

.comforterText {
    font-family: 'Comforter Brush', cursive;
}

.bgBlue {
    background: #55a6ff;
}

.digital-seo-page .digitalPageContent p.textColor01 {
    color: #163f8f;
}

.holyGrail p span {
    font-family: 'Dancing Script', cursive;
    font-size: 1.8em;
}

.lg-img04 {
    width: 100%;
    height: auto;
}

.lgh-box {
    background: #163f8f;
    padding: 2rem;
    height: 100%;
}

.lg-faqs .lgh-box {
    background: #fff;
    padding: 0;
    height: auto;
}

.lgh-box h4 {
    font-size: 2.2rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1rem;
}

.lgh-box p {
    font-size: 0.9rem;
}

.leadSection4 .lgh-box h4 {
    text-transform: none;
    font-size: 1.9rem;
}

.digital-seo-page .lgh-box h4 {
    font-size: 1.7rem;
}

.igbg-1 {
    background: #82ca9c;
}

.igbg-2 {
    background: #6dcff6;
}

.igbg-3 {
    background: #8393ca;
}

.igbg-4 {
    background: #bd8cbf;
}

.igbg-5 {
    background: #abd373;
}

.igbg-6 {
    background: #82ca9c;
}

.igbg-7 {
    background: #7cc576;
}

.igbg-8 {
    background: #c4df9c;
}

.igbg-9 {
    background: #8493ca;
}

.igbg-10 {
    background: #bd8dbf;
}

.igbg-9 {
    background: #8493ca;
}

.igbg-10 {
    background: #26ffd4;
}

.igbg-11 {
    background: #a3d39c;
}

.igbg-12 {
    background: #8781bd;
}

.igText-01 {
    color: #2696ff;
}

.igText-02 {
    color: #7e841f !important;
}

.igText-03 {
    color: #fff000 !important;
}

.igText-04 {
    color: #5c5594 !important;
}

.lgServices h3 {
    font-size: 5rem;
    color: #8393ca;
}

.lgServices h4 {
    font-size: 1.7rem;
    font-weight: 600;
}

.lgServices-2 h3 {
    font-size: 2.15rem;
    line-height: 1.1;
}

.lg-faqs h2 {
    font-size: 4.5rem;
    font-weight: 600;
    color: #545454;
}

.lg-faqs .text-black {
    color: #545454 !important;
}

.leadSection2 h3 {
    font-size: 2rem;
    font-weight: 600;
}

.leadSection3 h3 {
    font-size: 2.9rem;
    font-weight: 600;
}

.decorativeEle {
    position: absolute;
    z-index: 2;
    width: 40px;
    height: 40px;
    background: #6a6c46;
}

.decorative01 {
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
}

.decorative02 {
    right: 0;
    bottom: 0;
    transform: translate(50%, 50%) rotate(180deg);
}

.decorativeEle::after {
    position: absolute;
    z-index: 1;
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    right: -30%;
    background: #6a6c46;
    transform-origin: left bottom;
    transform: skewX(-45deg);
}

.decorativeEle::before {
    position: absolute;
    z-index: 1;
    content: "";
    left: 0;
    top: 0;
    bottom: -30%;
    right: 0;
    background: #6a6c46;
    transform-origin: right top;
    transform: skewY(-45deg);
}

.cs-note {
    background: #d6f3ff;
    border-radius: 2rem;
    font-size: 1.1em;
    font-weight: 600;
}

.cs-note.alt01 {
    background: #ffdfef;
}

.cs-note.alt02 {
    background: #74fbc6;
}

.ad-imageWrap {
    border-radius: 2rem;
    overflow: hidden;
    background: #ecd8fb;
}

.ad-imageWrap.alt {
    background: #ffe7d0;
}

.ad-imageWrap img {
    border-radius: 2rem;
}

.ad-imageWrap p {
    font-size: 1.1em;
    padding: 2.5em 1.5em;
    font-weight: 600;
}

.lg-faqs p span {
    font-weight: 600;
}

.servicesListContainer a {
    color: var(--swcBlack);
    text-decoration: none !important;
}

.servicesListItem {
    aspect-ratio: 1/0.75;
    display: flex;
    align-items: center;
    justify-content: center;
}

.digital-page .brandIdentity .servicesListItem {
    aspect-ratio: 1/0.6;
}

.digital-page .servicesListItem h2 {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 0.95;
    text-decoration: none;
}

.digital-page .brandIdentity .servicesListItem h2 {
    font-size: 2.8rem;
    font-weight: 800;
}

.digital-page .servicesListItem h2 span {
    display: block;
}

.digital-page .servicesListItem.servicesListItem_1 h2 span {
    font-size: 2.65em;
    font-weight: 800;
}

.digital-page .servicesListItem.servicesListItem_2 h2 span:nth-child(1) {
    font-size: 1.72em;
    font-weight: 800;
    line-height: 0.8;
}

.digital-page .servicesListItem.servicesListItem_2 h2 span:nth-child(2) {
    font-size: 2em;
    font-weight: 600;
}

.digital-page .servicesListItem.servicesListItem_3 h2 span:nth-child(1) {
    font-size: 1.08em;
    font-weight: 800;
}

.digital-page .servicesListItem.servicesListItem_3 h2 span:nth-child(2) {
    font-size: 1.385em;
    font-weight: 800;
}

.servicesParent .servicesListItem {
    aspect-ratio: 1;
}

.readMoreBtn-Text {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.readMoreBtn-Text::after {
    position: absolute;
    z-index: 1;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: #111;
}

.text-white .readMoreBtn-Text::after {
    background: #fff;
}

@media( min-width: 1400px ) {
    .lg-img01,
    .lg-img02,
    .lg-img03 {
        height: 380px;
    }
    .lgHightlights .container-fluid {
        max-width: 1700px;
    }
}

@media( max-width: 1200px ) {
    .lg-img01,
    .lg-img02,
    .lg-img03 {
        height: 280px;
    }
}

@media( max-width: 991px ) {
    .lg-img01,
    .lg-img02,
    .lg-img03 {
        height: 220px;
    }
    .unbranding-page .servicespageBanner h1,
    .unadvertising-page .servicespageBanner h1 {
        font-size: 72px;
        padding-left: 1em;
    }
    .category .servicespageBanner h1 {
        font-size: 4rem;
    }
    .lgServices h3.igText-02 {
        font-size: 3.5rem;
    }
}

@media( max-width: 767px ) {
    .lgServices-bxs .container {
        padding: 0 1rem !important;
    }
    .lgServices-bxs .px-5 {
        padding: 2rem !important;
    }
    .decorativeEle {
        display: none;
    }
    .ad-imageWrap {
        border-radius: 1rem;
    }
    .ad-imageWrap img {
        border-radius: 1rem;
    }
}

@media( max-width: 575px ) {
    .lg-img01,
    .lg-img02 {
        height: 54vw;
    }
    .lg-img03 {
        height: auto;
        width: 100%;
    }
    .lgh-box p {
        font-size: 1rem;
    }
    .category .servicespageBanner h1 {
        font-size: 10vw;
    }
    .unbranding-page .servicespageBanner h1,
    .unadvertising-page .servicespageBanner h1 {
        font-size: 11vw;
        transform: translateX(3%);
    }
    .servicespageBanner {
        padding: 7rem 0 3rem !important;
    }
    .digital-page .brandIdentity .servicesListItem {
        aspect-ratio: 1/0.5;
    }
    .digital-page .brandIdentity .servicesListItem h2 {
        font-size: 2.2rem;
    }
}