.weirdosPage {
    position: fixed;
    inset: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 10;
}

.weirdosPage img {
    height: auto;
}

.WeirdosBannerArea img {
    width: 100%;
    position: relative;
}

.WeirdosBannerArea h1 {
    color: var(--swcWhite);
    font-size: max( 7.5vw, 72px );
    display: inline-block;
    position: absolute;
    z-index: 3;
    top: 50%;
    right: 1.5rem;
    margin: 0;
    line-height: 0.7;
    transform-origin: right center;
    transform: translateY(-50%) rotate(-90deg) translateX(50%) translateY(-50%);
}

.weirdosSection2Left {
    background: var(--swcGreenDarkAlt);
}

.weirdosSection2Left img {
    width: 90%;
}

.weirdosSection2Right {
    background: var(--swcBlue);
}

.weirdosSection2Right h1 {
    line-height: 0.85;
    font-size: 10vw;
}

.weirdosSection3 h5 {
    color: var(--swcTextColor);
    font-size: 1.5rem;
}

.weirdosSection4 h2 {
    color: var(--swcTextColor);
    font-size: 2.4rem;
}

.weirdosSection5Inner {
    background: var(--swcYellow);
    height: 100%;
    padding: 0px 15%;
}

.weirdosSection5Inner h3 {
    font-size: 3.2rem;
}

.weirdosSection5Inner p span {
    font-weight: 700;
}

.weirdosSection5 .col-lg-4:nth-child(1) .weirdosSection5Inner h3 {
    color: var(--swcBlue);
}

.weirdosSection5 .col-lg-4:nth-child(2) .weirdosSection5Inner {
    background: var(--swcGreen);
}

.weirdosSection5 .col-lg-4:nth-child(2) .weirdosSection5Inner h3 {
    color: white;
}

.weirdosSection5 .col-lg-4:nth-child(3) .weirdosSection5Inner {
    background: var(--swcBlue);
}

.weirdosSection5 .col-lg-4:nth-child(3) .weirdosSection5Inner h3 {
    color: var(--swcGreen);
}

.subscription h6 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 1;
    transform: translateY(10%);
}

.subscription label {
    line-height: 1;
    transform: translateY(45%);
}

.subscription .form-control {
    border: none !important;
    border-bottom: 1px solid #000 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    outline: none !important;
    font-size: 16px;
}

.subscription .form-control:hover,
.subscription .form-control:focus,
.subscription .form-control:active {
    border-color: #2696ff !important;
}

.subscription .form-control.field-error {
    border-color: #ed1c24 !important;
}

.subscription .form-group span.error {
    position: absolute;
    top: 100%;
    right: 0;
}

.subscription .btn_sub {
    background: #040707 !important;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: 400;
    border-radius: 0 !important;
    border: none !important;
    box-shadow: none !important;
}

@media( max-width: 1199px ) {
    .WeirdosBannerArea img {
        width: 100%;
        min-height: 100vh;
        object-position: center center;
        object-fit: cover;
        position: relative;
    }
}

@media( max-width: 575px ) {
    .weirdosSection5Inner {
        padding: 0px 3rem;
    }
    .weirdosSection2Right h1 {
        font-size: 22vw;
        padding: 0.5em 0;
    }
}