.swcFooter {
    background: var(--swcBlack);
    padding: 3rem 1rem;
}

.swcFooter h4 {
    font-size: 1rem;
    color: white;
    font-weight: 700;
}

.swcFooter h4 a {
    text-decoration: none;
    color: var(--swcBlue);
    text-transform: uppercase;
}

.swcFooter h5,
.swcFooter h5 a {
    text-decoration: none;
    color: var(--swcGreen);
    font-size: 0.95rem;
    margin-bottom: 0.5rem;
}

.swcFooter p a {
    text-decoration: none;
    color: white;
}

.swcFooterLogo {
    width: 7rem;
}

.footer-about {
    font-size: 0.9rem;
}

.socialLinks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.socialLinks p {
    padding: 0.4em 0.7em 0 0;
}

@media( max-width: 575px ) {
    .swcFooter h5 {
        padding: 0.4em 0.8em;
    }
    .swcFooter h4 {
        font-size: 1.5rem;
    }
    .footerColFlex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    .footerColFlex h4 {
        width: 100%;
    }
    .socialLinks p {
        padding: 0.4em 1em 0;
    }
}