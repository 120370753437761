

.thanathuContent {
    background: var(--swcBlack);
}

.thanathuPageWrap {
    max-width: 100wh;
    overflow-x: hidden;
    height: 100vh;
}

.thanathuHeader {
    background: var(--swcBlack);
    padding: 7rem 0 5rem;
    border-bottom: 1px solid #fff;
    position: relative;
    z-index: 1;
}

.thanathuHeader::after {
    position: absolute;
    z-index: 1;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%) scale(0.7);
    width: 0;
    height: 0;
    content: "";
    border: 25px solid transparent;
    border-top-color: #fff;
    border-top-width: 30px;
    border-bottom-width: 0;
}

.thanathuHeader h1 {
    font-family: 'Noto Sans Malayalam', sans-serif;
    font-weight: 300;
    font-size: 16rem;
    line-height: 1;
    letter-spacing: 0.1em;
}

.thanathuContent {
    padding: 6rem 0;
    border-bottom: 1px solid #fff;
}

.thanathuContent p {
    font-size: 0.95rem;
    font-weight: 200;
}

.rooted p {
    width: 17rem;
    border-bottom: 1px solid #fff;
    margin-left: 10rem;
}

.rooted h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 12.3rem;
    line-height: 1;
    margin: 0;
    transform: scale(1.05);
}

.rooted h2 span:nth-child(1) {
    font-weight: 100;
}

.rooted h2 span:nth-child(2) {
    font-weight: 300;
}

.rooted h2 span:nth-child(3) {
    font-weight: 400;
}

.rooted h2 span:nth-child(4) {
    font-weight: 600;
}

.rooted h2 span:nth-child(5) {
    font-weight: 700;
}

.rooted h2 span:nth-child(6) {
    font-weight: 900;
}

.image01 {
    position: absolute;
    right: 1rem;
    width: 4rem;
    bottom: 0;
}

.designBetter h4 {
    font-weight: 200;
    font-size: 2rem;
    padding-left: 0.4rem;
}

.designBetter h2 {
    font-family: 'moonDream';
    font-weight: normal;
    text-transform: uppercase;
    font-size: 5.895rem;
    line-height: 1;
}

.designBetter p {
    padding-left: 62%;
}

.cultures p {
    font-size: 2.55rem;
    line-height: 1.5;
}

.image02 {
    max-width: 90%;
}

.whoWeAre .imageWrap {
    width: 100%;
    height: 11.67rem;
}

.whoWeAre h3 {
    font-family: 'moonDream';
    font-weight: normal;
    margin-bottom: 0;
    padding-bottom: 0.1em;
    font-size: 2.4rem;
    line-height: 1;
}

.whoWeAre p {
    font-size: 0.735rem;
}

.wrap01 .imageWrap {
    background-image: url('../assets/images/thanathuImage03.png');
    background-size: contain;
    background-position: left bottom;
    background-repeat: no-repeat;
}

.wrap02 .imageWrap {
    background-image: url('../assets/images/thanathuImage04.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.wrap03 .imageWrap {
    background-image: url('../assets/images/thanathuImage05.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 13.89rem !important;
    margin-bottom: -3.3rem;
}

.wrap04 .imageWrap {
    background-image: url('../assets/images/thanathuImage06.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 13.89rem !important;
    margin-bottom: -3.3rem;
}

.wrap04 h3,
.wrap04 p {
    padding-left: 6.11rem;
}

.wrap05 .imageWrap {
    background-image: url('../assets/images/thanathuImage07.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 13.89rem !important;
}

.wrap06 .imageWrap {
    background-image: url('../assets/images/thanathuImage08.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 13.89rem !important;
    margin-bottom: -2.11rem;
}

.wrap06 p {
    width: 14.44rem;
}

.wrap07 .imageWrap {
    background-image: url('../assets/images/thanathuImage09.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 13.89rem !important;
}

.wrap07 p {
    width: 14.44rem;
}

.whoWeAre h5 {
    
    font-weight: 200;
    font-size: 1.8rem;
    border: 1px solid #fff;
    margin-bottom: -1px;
    padding: 0.75em 1em;
    border-radius: 0.4rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.weAre h2 {
    font-family: 'moonDream';
    font-weight: normal;
    text-transform: uppercase;
    font-size: 4.1rem;
    line-height: 1;
}

.weAre h4 {
    font-family: 'moonDream';
    font-weight: normal;
    font-size: 2.6rem;
    line-height: 1;
}

.thanathu-page .swcFooter * {
    color: #fff !important;
}

.thanathu-page .contact h3 {
    
    font-weight: 700;
    font-size: 1.5rem;
}

.thanathu-page .contact h4 {
    
    font-weight: 400;
    font-size: 1.2rem;
    color: #777777;
}

.thanathu-page .contact p {
    font-size: 0.9rem;
    color: #777777;
    
    font-weight: 400;
}

.thanathu-page .contact p a {
    color: #777777;
    text-decoration: none;
}

.thanathu-page .form-control {
    
    font-weight: 400;
    font-size: 0.9rem;
    min-height: 45px;
    border-radius: 0;
    border: 1px solid #777777;
    box-shadow: none !important;
}

.thanathu-page .form-control:active,
.thanathu-page .form-control:focus,
.thanathu-page .form-control:hover {
    border-color: #000;
}

.thanathu-page textarea.form-control {
    min-height: 150px;
    height: 100%;
    resize: none;
}

.thanathu-page .btn-submit {
    background: var(--swcBlack) !important;
    color: #fff !important;
    text-transform: uppercase;
    
    font-size: 0.9rem;
    font-weight: 600;
    min-height: 45px;
}

.thanathu-page .logoBlack {
    display: none;
}

.thanathu-page .logoWhite {
    display: block;
    background: var(--swcBlack);
}

.whoWeAre-lg {
    padding-top: 5.25rem;
    padding-bottom: 5.25rem;
}

.whoWeAre-lg h3 {
    font-size: 4.5rem;
    text-transform: capitalize;
}

.whoWeAre-lg h3 span {
    display: block;
    font-size: 1.7em;
}

.whoWeAre-lg p {
    font-size: 1.4rem;
    width: min( 100%, 27rem );
    line-height: 1.3;
}

.whoWeAre-lg .imageWrap {
    background-image: url('../assets/images/thanathuImage08-a.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    height: auto;
}

@media( min-width: 1400px ) {
    .thanathuHeader h1 {
        font-size: 18rem;
    }
    .rooted h2 {
        font-size: 12.8rem;
        transform: scale(1.05);
    }
    .rooted p {
        margin-left: 11rem;
    }
    .designBetter h2 {
        font-size: 6.15rem;
    }
    .designBetter p {
        padding-left: 61%;
    }
    .cultures p {
        font-size: 2.6rem;
        line-height: 1.55;
    }
    .whoWeAre-lg {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }
}

@media( max-width: 1199px ) {
    .thanathuHeader h1 {
        font-size: 12rem;
    }
    .rooted h2 {
        font-size: 10.4rem;
        transform: scale(1.05);
    }
    .rooted p {
        margin-left: 7.5rem;
    }
    .image01 {
        width: 3.5rem;
    }
    .designBetter h2 {
        font-size: 4.95rem;
    }
    .designBetter p {
        padding-left: 60%;
    }
    .cultures p {
        font-size: 2.15rem;
        line-height: 1.5;
    }
    .whoWeAre-lg {
        padding-top: 4.6rem;
        padding-bottom: 4.6rem;
    }
    .whoWeAre-lg h3 {
        font-size: 3.7rem;
    }
    .whoWeAre-lg p {
        font-size: 1.3rem;
        width: min( 100%, 25rem );
    }
}

@media( max-width: 991px ) {
    .rooted h2 {
        font-size: 8.4rem;
        transform: scale(1.05);
    }
    .rooted p {
        width: 17rem;
        margin-left: 6rem;
    }
    .image01 {
        width: 3rem;
    }
    .designBetter h4 {
        font-size: 1.8rem;
    }
    .designBetter h2 {
        font-size: 6rem;
    }
    .designBetter p {
        padding-left: 0.4rem;
    }
    .cultures p {
        font-size: 1.7rem;
        line-height: 1.5;
    }
    .whoWeAre-lg {
        padding-top: 3.6rem;
        padding-bottom: 3.6rem;
    }
    .whoWeAre-lg h3 {
        font-size: 3rem;
    }
    .whoWeAre-lg p {
        font-size: 1.1rem;
        width: min( 100%, 22rem );
    }
}

@media( max-width: 767px ) {
    .thanathuHeader h1 {
        font-size: 10rem;
    }
    .rooted h2 {
        font-size: 7rem;
        transform: scale(1.05);
    }
    .rooted p {
        width: 22rem;
        margin-left: 0rem;
    }
    .cultures p {
        font-size: 1.45rem;
        line-height: 1.5;
    }
    .whoWeAre-lg {
        padding-top: 1.8rem;
        padding-bottom: 1.8rem;
    }
}

@media( max-width: 575px ) {
    .thanathuHeader {
        padding: 10rem 0 4rem;
    }
    .thanathuHeader::after {
        transform-origin: center center;
        transform: translateX(-50%) scale(0.45);
    }
    .thanathuHeader h1 {
        font-size: 25vw;
    }
    .rooted p {
        width: auto;
        margin-right: min( 20vw, 5rem );
    }
    .rooted h2 {
        font-size: 18.5vw;
        transform: scale(1.05);
    }
    .image01 {
        width: min( 10vw, 3rem );
    }
    .designBetter h4 {
        font-size: 1.5rem;
    }
    .designBetter h2 {
        font-size: 18vw;
    }
    .cultures p {
        font-size: 1.2rem;
        line-height: 1.5;
    }
    .cultures p br {
        display: none;
    }
    .whoWeAre-lg {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .whoWeAre-lg h3 {
        font-size: 9vw;
    }
    .whoWeAre-lg p {
        font-size: 1rem;
        width: min( 100%, 20rem );
    }
    .whoWeAre-lg .imageWrap {
        background-position: right top;
        width: 40%;
        top: -1rem;
    }
}

@media( max-width: 350px ) {

}