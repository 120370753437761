.single-work {
    padding: 140px 0 80px;
}

.workspageHeader h1.moonDreamText {
    font-size: 4rem;
}

.single-work p {
    text-align: justify;
}

.pageBreadcrumb h6 {
    font-size: 14px;
}

.pageBreadcrumb a {
    text-decoration: none;
    color: #000;
}

.pageBreadcrumb a:hover,
.pageBreadcrumb a:active,
.pageBreadcrumb a:focus {
    color: #ed1c24;
}

.singleWorkHead {
    padding-right: 30%;
}

.single-work h1.singleWorkTitle {
    font-family: 'moonDream' !important;
    font-weight: normal;
    font-size: 3.4rem;
}

.categoryBadge {
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    height: auto;
    padding: 0 !important;
}

.categoryBadge .cb-stator {
    width: 100%;
    height: 100%;
}

.categoryBadge .cb-rotor {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    transform: rotate(0deg);
    animation: cb-rotor-rotate 4s linear infinite;
}

@keyframes cb-rotor-rotate {
    0% {
        transform: rotate(0deg);
    } 
    100% {
        transform: rotate(360deg);
    }
}

.categoryBadge.results {
    position: absolute;
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
    width: 15% !important;
    height: auto;
    transform: translateY(-50%) translateX(-10%);
}

.workMeta h4 {
    font-size: 20px;
    font-weight: 700;
}

.workMeta h5 {
    font-size: 20px;
    color: #a7a9ac;
    font-weight: 400;
}

.singleWorkContent {
    height: auto;
    width: 100%;
}

.aptayuLogoGif {
    margin-bottom: -10px;
    background: #258661;
}

.apttayuImgGrp01 {
    background: #f1f2f2;
}

.apttayuImgGrp01 img {
    width: auto;
    height: 502px;
}

.workApproach {
    min-height: max( 45vw, 650px );
}

.workApproach h2 {
    font-family: 'moonDream' !important;
    font-weight: normal;
    font-size: 52px;
}

.workApproachBG {
    position: absolute;
    z-index: 1;
    inset: 0;
    background: #000;
}

.workApproachBG div{
    height: 100%;
}

.workApproachAccordion {
    position: relative;
    z-index: 3;
}

.workApproachAccordion .row {
    min-height: max( 45vw, 650px );
}

.wAAContent {
    background: #fff;
    padding-left: min( 150px, 13vw );
    border: 1px solid #e0e0e0;
    border-left: none;
    height: 100%;
    overflow: hidden;
}

.wAAContentTrigger {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    bottom: 0;
    width: min( 150px, 13vw );
    cursor: pointer;
}

.wAAContent .wAAContentHead {
    position: absolute;
    left: 50px;
    top: 20px;
    width: 100vw;
    z-index: 1;
}

.wAAContent .wAAContentHead h3 {
    position: absolute;
    left: 0;
    top: 0;
    font-family: 'manrope';
    font-weight: 800;
    margin: 0;
    font-size: min( 3.2vw, 56px );
    line-height: 1;
    transform-origin: left bottom;
    transform: rotate(90deg) translateX(40px);
}

.wAAContent .wAAContentHead h4 {
    position: absolute;
    width: min( 3vw, 52px );
    height: min( 3vw, 52px );
    line-height: 1;
    background: #ed1c24;
    color: #fff;
    font-family: 'manrope';
    font-weight: 800;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 40px;
    left: 0;
    transform: translateX(10%);
}

.wAAContentBody {
    overflow: hidden;
    width: 0;
    transition: all 0.5s ease-in-out;
}

.wAAOpen .wAAContentBody {
    width: max( 560px, 50vw );
}

.wAAContentBody p {
    width: max( 560px, 50vw );
}

.workResults {
    background: #e6e7e8;
}

.workResults h2 {
    font-family: 'moonDream' !important;
    font-weight: normal;
    font-size: 100px;
    text-transform: uppercase;
}

.works-page .bannerVideoWrap {
    min-height: auto !important;
    max-height: auto !important; 
    height: auto !important;
}

.works-page .bannerVideoWrap video {
    min-height: auto !important;
}

.ptototypeWrap::after {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 50%;
    background: #eaeaea;
    content: "";
}

.wp-btn {
    font-family: 'manrope' !important;
    font-weight: 700 !important;
    background: transparent;
    padding: 0.2em 0.8em !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.wp-btn::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 50%;
    content: "";
    background: #eaeaea;
    z-index: -1;
    transition: all 0.3s ease-in-out;
}

.wp-btn:hover::after {
    background: #ed1c24;
}

.ayurcentralGrid {
    background: #e5e5e5;
}

.videoWrap {
    background-image: url(../assets/images/works/video/hama/videos-hamaImage01.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #e8e6e6;
}

.videoWrap.lazza {
    background-image: url(../assets/images/works/video/lazza/lazza-video-bg.png);
}


.videoWrap .yt-video {
    aspect-ratio: 1/0.5625;
}

/*------- WORKS PAGE ----------*/

.workspageHeader {
    padding: max( 15vh, 8rem ) 0 max( 7vh, 3rem ) !important;
}

.workFilter {
    display: flex;
    justify-content: flex-start;
    gap: 1rem 4rem;
    flex-wrap: wrap;
    padding-top: 2rem;
    padding-right: 20%;
}

.workFilterTab {
    display: inline-block;
    font-size: 1rem;
    cursor: pointer;
}

.worksList a {
    text-decoration: none;
    color: var(--swcBlack) !important;
}

.worksListItem {
    transform: scale(1);
    opacity: 1;
    transition: all 0.4s ease-in-out;
}

.worksListItem.hide {
    transform: scale(0);
    opacity: 0;
}

.worksListItem > div {
    overflow: hidden;
}

.worksListItem img {
    transform: scale(1);
    transition: all 0.5s ease-in-out;
}

.worksListItem:hover img {
    transform: scale(1.1);
}

.worksListItem h4 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.3rem;
}

.worksListItem:hover h4 {
    text-decoration: underline;
}

.worksListItem h6 {
    font-size: 0.9rem;
    font-weight: 300;
}

/*---------------------------- GRAND HAYATT ---------------*/
.hayattGrid {
    background-color: #e6e7e8;
    background-image: url(../assets/images/works/communication/grandhayatt/Grand-Hyatt-Kochi--bg.jpg);
    background-position: center top;
    background-size: 100% auto;
}

.hayattGrid img {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.hayattGrid img.no-shadow {
    box-shadow: none;
}

.hayattGrid img.rounded {
    border-radius: 0.75rem !important;
}

.hayattGrid .sectionTitle {
    font-family: 'moonDream' !important;
    font-weight: normal;
    font-size: 3.4rem;
}

@media( min-width: 1400px ) {
    .pageBreadcrumb h6 {
        font-size: 18px;
    }
    .singleWorkTitle {
        font-size: 76px;
    }
    .workMeta h4,
    .workMeta h5 {
        font-size: 24px;
    }
    .apttayuImgGrp01 img {
        height: 636px;
    }
    .workApproach,
    .workApproachAccordion .row {
        min-height: min( 55vw, 900px );
    }
    .workResults h2 {
        font-size: 120px;
    }
    .workApproach h2 {
        font-size: 72px;
    }
    .workFilter {
        padding-right: 30%;
    }
}

@media( max-width: 1199px ) {
    .apttayuImgGrp01 img {
        height: 420px;
    }
    .workApproach,
    .workApproachAccordion .row {
        min-height: 700px;
    }
    .workResults h2 {
        font-size: 82px;
    }
}

@media( max-width: 991px ) {
    .workMeta h4,
    .workMeta h5 {
        font-size: 18px;
    }
    .pageBreadcrumb h6 {
        font-size: 15px;
    }
    .apttayuImgGrp01 img {
        height: 312px;
    }
    .singleWorkTitle {
        font-size: 52px;
    }
    .wAAContentBody,
    .wAAOpen .wAAContentBody,
    .wAAContentBody p {
        width: 100%;
    }
    .workApproach,
    .workApproach > div,
    .workApproachAccordion .row {
        min-height: auto !important;
        position: relative;
    }
    .wAAContent {
        border: 1px solid #e0e0e0;
        border-top: none;
        padding-left: 0;
        padding-top: 2rem;
        height: auto !important;
    }
    .wAAContent .wAAContentHead {
        width: 90%;
        position: relative;
        left: 0;
    }
    .wAAContent .wAAContentHead h3 {
        position: static;
        font-size: 36px;
        line-height: 1;
        transform-origin: left bottom;
        transform: none;
        padding-left: 40px;
    }
    .wAAContent .wAAContentHead h4 {
        position: absolute;
        width: 30px;
        height: 30px;
        font-size: 12px;
        transform: translateY(2%);
        left: 0;
        top: 0;
    }
    .wAAContentTrigger {
        display: none;
    }
    .categoryBadge.results {
        display: none;
    }
}

@media( max-width: 767px ) {
    .apttayuImgGrp01 img {
        height: auto
    }
    .singleWorkHead {
        padding-right: 0%;
    }
    .pageBreadcrumb {
        padding-right: 100px;
    }
    .categoryBadge {
        width: 130px;
        transform: translate( 20%, -70%);
    }
    .single-work {
        padding: 180px 0 80px;
    }
    .workResults h2 {
        font-size: 72px;
    }
    .workFilter {
        gap: 0.8rem 3rem;
        padding-right: 0;
    }
    .hayattGrid .sectionTitle {
        font-size: 2rem;
    }
}

@media( max-width: 575px ) {
    .wAAContent .wAAContentHead h3 {
        font-size: 32px;
        padding-left: 40px;
    }
    .wAAContent .wAAContentHead h4 {
        font-size: 10px;
    }
    .workResults h2 {
        font-size: 62px;
    }
    .workspageHeader {
        padding: 12rem 0 2rem !important;
    }
}