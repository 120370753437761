.contactPageWrap {
    height: 100vh;
    overflow-x: hidden;
    max-width: 100vw;
}

.contactPageContent {
    min-height: 100vh;
    background: #26629e;
    padding: 100px 0px;
}

.contactPageHead {
    font-size: 4.5rem;
    padding-bottom: 1rem;
}

.contactFormWrap .form-group {
    position: relative;
    overflow: hidden;
    padding-bottom: 2rem;
}

.contactFormWrap .form-group::before {
    position: absolute;
    z-index: 1;
    content: "";
    width: 70%;
    height: 70%;
    bottom: 3.3rem;
    left: 4%;
    background: #000;
    transform-origin: right;
    transform: skewY(-6.5deg);
    filter: blur(8px);
    opacity: 0.45;
}

.contactFormWrap .form-group::after {
    position: absolute;
    z-index: 1;
    content: "";
    width: 70%;
    height: 70%;
    bottom: 3.3rem;
    right: 4%;
    background: #000;
    transform-origin: left;
    transform: skewY(6.5deg);
    filter: blur(8px);
    opacity: 0.45;
}

.contactFormWrap .form-group.alt::before,
.contactFormWrap .form-group.alt::after {
    bottom: 2.3rem;
    filter: blur(5px);
}

.contactFormWrap .form-control,
.contactFormWrap .form-control:focus,
.contactFormWrap .form-control:active,
.contactFormWrap .form-control:hover {
    border-radius: 5px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 1rem 2rem;
    z-index: 2;
    position: relative;
    resize: none;
}

.contactFormWrap .btnSubmit {
    background: #00ffff;
}
 
.contactFormWrap .form-control.field-error {
    box-shadow: #ed1c24dd 0 2px 0px !important;
}

span.error {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 1rem;
    color: #ed1c24;
    transform: translateY(-150%);
    z-index: 3;
    font-size: 0.8rem;
}

.formResponseHolder {
    display: flex;
    min-height: 40vh;
    justify-content: center;
    align-items: center;
}

.formResponse {
    font-weight: 500;
}

.formResponse span {
    font-size: 2em;
    font-weight: 700;
    color: var(--swcGreen);
    line-height: 1;
    display: inline-block;
    margin-bottom: 0.5rem;
}

.formResponse span.text-danger {
    color: #ed1c24 !important;
}

@media( max-width: 575px ) {
    .contactPageHead {
        font-size: 4rem;
        padding-top: 4rem;
        padding-bottom: 2rem;
    }
}